
import { computed, defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import UserOfficesTariffSelect from "@/components/catalogs-select/UserOfficesTariffSelect.vue";
import { useI18n } from "vue-i18n";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  name: "RateCriteriaLevel",
  components: {
    TableBasicSelect,
    UserOfficesTariffSelect,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const header = ref([
      { column: t("iname"), key: "name" },
      { column: t("ilevel"), key: "level", size: "small" },
      { column: t("icode"), key: "code", size: "small" },
      {
        column: t("iisoptional"),
        key: "is_optional",
        size: "small",
        type: "check",
      },
      {
        column: t("iismandatory"),
        key: "is_mandatory",
        size: "small",
        type: "check",
      },
    ]);
    const showDelete = ref(false);
    const deleteId = computed(() => store.getters.DeleteId);
    const isLoading = ref(true);
    const search = ref("");
    const tableButtons = ref([]);
    const dataList = ref([]);
    const dataObject = ref([] as any);
    const dialogVisible = ref(false);
    const itemID = ref("");
    const modalMode = ref("add");
    const userOffices = ref([]) as any;
    const offices = ref([]) as any;
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      last_page: 1,
      total: 1,
    });

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      getItems();
    };

    const currentPageChange = (val) => {
      pagination.value.page = val;
      getItems();
    };
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "tariffcriteria: create")
            .length > 0
        );
      }
      return false;
    });

    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "tariffcriteria: show")
            .length > 0
        );
      }
      return false;
    });

    const canUpdate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "tariffcriteria: update")
            .length > 0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "tariffcriteria: delete")
            .length > 0
        );
      }
      return false;
    });

    const createElement = () => {
      router.push({ name: "costTariffCriteriaLevelCreate" });
    };

    const getUserOffices = () => {
      const temp = localStorage.getItem("user") as any;
      const user = JSON.parse(temp);
      const payload = {
        per_page: 25,
        users: [user.id],
      };
      store.commit("setLoading", false);
      ApiService.query("/api/offices/lists", {
        params: payload,
      }).then(({ data }) => {
        userOffices.value = data.offices.length
          ? data.offices.map((x) => x.id)
          : [];
        getItems();
      });
    };

    const getItems = async () => {
      store.commit("setLoadingStatus", true);
      isLoading.value = false;
      dataObject.value = [];
      store.commit("setLoading", false);
      const response: any = await store.dispatch("getQuotationsCost", {
        per_page: pagination.value.rowsPerPage,
        page: pagination.value.page,
        search: search.value === "" ? undefined : search.value,
        cost_type_id: "[3]",
        offices_id: !offices.value.length
          ? JSON.stringify(userOffices.value)
          : JSON.stringify(offices.value),
      });
      pagination.value.total = response.data.total;
      pagination.value.page = response.data.current_page;
      pagination.value.last_page = response.data.last_page;
      if (response.data) {
        const items: Record<string, any> = [];
        dataList.value = response.data.data;
        tableButtons.value = [
          {
            name: "Editar" as never,
            type: "EDIT" as never,
            color: "primary" as never,
          } as never,
        ];
        store.commit("setTariff", response.data.data);
        response.data.data.forEach((element) => {
          const td = {
            id: element.id,
            name: element.name,
            level: element.level ? element.level : "",
            code: element.billing_concept_code.name,
            is_optional: element.is_optional === 0 ? "0" : "1",
            is_conditional: element.is_conditional === 0 ? "0" : "1",
            is_mandatory: element.is_mandatory === 0 ? "0" : "1",
            buttons: [{ type: "EDIT", id: element.id }],
          };
          if (element.cost_type_id === 3) {
            items.push(td);
          }
        });
        items.sort((a, b) => {
          return b.id - a.id;
        });
        dataObject.value = items;
        store.commit("setLoadingStatus", false);
      }
    };

    const resetModal = () => {
      dialogVisible.value = false;
      getItems();
    };

    onMounted(() => {
      console.clear();
      setCurrentPageBreadcrumbs("tariff_cost_criteria_level", [
        { route: "/dashboard", label: "" },
      ]);
      getUserOffices();
    });

    const deleteDialog = (value) => {
      store.commit("setDeleteId", value);
      showDelete.value = true;
    };

    const deleteElement = () => {
      store.commit("setLoading", false);
      ApiService.delete(`/api/tariff_level/${deleteId.value}`).then(
        ({ data }) => {
          store.commit("setDeleteId", undefined);
          showDelete.value = false;
          getItems();
        }
      );
    };

    const actionsButton = (param1, param2) => {
      if (param2 == "EDIT") {
        router.push({
          name: "costTariffCriteriaLevelEdit",
          params: { id: param1 },
        });
      }
      if (param2 == "REMOVE") {
        deleteDialog(param1);
      }
    };

    const onSearch = () => {
      if (search.value !== "") {
        isLoading.value = false;
        dataObject.value = [];
        store.commit("setLoading", false);
        ApiService.query("/api/tariff_level", {
          params: {
            per_page: pagination.value.rowsPerPage,
            search: search.value,
          },
        })
          .then((response: any) => {
            if (response.data.data) {
              store.commit("setTariff", response.data.data);
              store.commit("setSaleLevelCont", 1);
              pagination.value.total = response.data.total;
              pagination.value.page = 1;
              pagination.value.last_page = 1;
              const items: Record<string, any> = [];
              dataList.value = response.data.data;
              tableButtons.value = [
                {
                  name: "Editar" as never,
                  type: "EDIT" as never,
                  color: "primary" as never,
                } as never,
              ];
              response.data.data.forEach((element) => {
                const td = {
                  id: element.id,
                  name: element.name,
                  level: element.level ? element.level : "",
                  code: element.billing_concept_code.name,
                  is_optional: element.is_optional === 0 ? "0" : "1",
                  is_conditional: element.is_conditional === 0 ? "0" : "1",
                  is_mandatory: element.is_mandatory === 0 ? "0" : "1",
                  buttons: [{ type: "EDIT", id: element.id }],
                };
                if (element.cost_type_id === 3) {
                  items.push(td);
                }
              });
              items.sort((a, b) => {
                return b.id - a.id;
              });
              dataObject.value = items;
            }
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }
    };

    return {
      user,
      canCreate,
      canShow,
      canUpdate,
      canDelete,
      dataList,
      dataObject,
      dialogVisible,
      itemID,
      modalMode,
      header,
      tableButtons,
      isLoading,
      search,
      showDelete,
      pagination,
      offices,
      getItems,
      currentPageChange,
      setItemsPerPage,
      deleteDialog,
      deleteElement,
      onSearch,
      actionsButton,
      createElement,
      resetModal,
    };
  },
});
